<template>
  <table class="listado table table-hover m-0 tabla-desktop">
    <thead class="bg-primary text-white">
      <th>{{ $t("Nombre") }}</th>
      <th>{{ $t("Estado") }}</th>
      <th class="th-acciones">{{ $t("Acciones") }}</th>
    </thead>
    <tbody>
      <tr v-for="causa in causas" v-bind:key="causa.id">
        <td>{{ causa.nombre }}</td>
        <td v-if="causa.activa == true">Activa</td>
        <td v-else>Inactiva</td>
        <td nowrap="nowrap" class="td-acciones">
          <botonera-acciones-tabla :causa="causa" @causaEliminada="onCausaEliminada" />
        </td>
      </tr>
      <sin-registros :count="causas.length" :colspan="6"></sin-registros>
    </tbody>
  </table>
</template>
<script>
import BotoneraAccionesTabla from "./BotoneraAccionesTabla.vue";
export default {
  props: {
    causas: Array,
  },
  methods: {
    onCausaEliminada(id) {
      this.$emit("causaEliminada", id);
    },
  },
  components: {
    BotoneraAccionesTabla,
  },
};
</script>
