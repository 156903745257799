<template>
  <div>
    <tabla-mobile :causas="causas" @causaEliminada="onCausaEliminada" />
    <tabla-desktop :causas="causas" @causaEliminada="onCausaEliminada" />
  </div>
</template>
<script>
import TablaMobile from "./TablaMobile.vue";
import TablaDesktop from "./TablaDesktop.vue";
export default {
  components: {
    TablaMobile,
    TablaDesktop,
  },
  props: {
    causas: Array,
  },
  methods: {
    onCausaEliminada(id) {
      this.$emit("causaEliminada", id);
    },
  },
};
</script>
