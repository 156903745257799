<template>
  <div class="listado tabla-mobile">
    <filtros-listado-mobile>
      <filtro-activo field="activa" />
    </filtros-listado-mobile>

    <fila-compacta v-for="(causa, idx) in causas" v-bind:key="idx" :titulo="causa.nombre">
      <div>
        {{ $t("Estado") }}:
        <span v-if="causa.activa == true">Activa</span>
        <span v-else>Inactiva</span>
      </div>

      <botonera-acciones-tabla slot="toolbar" :causa="causa" @causaEliminada="onCausaEliminada" />
    </fila-compacta>

    <h3 class="text-center" v-if="causas.length == 0">
      {{ $t("No hay causas") }}
    </h3>
  </div>
</template>

<script>
import BotoneraAccionesTabla from "./BotoneraAccionesTabla.vue";

export default {
  props: {
    causas: { type: Array, required: true },
  },
  data() {
    return {
      activa: 1,
    };
  },
  components: { BotoneraAccionesTabla },
  methods: {
    onCausaEliminada(id) {
      this.$emit("causaEliminada", id);
    },
  },
};
</script>
