<template>
  <acciones>
    <accion-primaria :label="$t('Editar')" icon="edit" :to="`/administracion/causas/editar/${causa.id}`" />

    <acciones-secundarias>
      <accion-eliminar @eliminar="eliminarCausa(causa)" :confirmacion="`¿Desea eliminar la causa ${causa.nombre}?`" />
    </acciones-secundarias>
  </acciones>
</template>
<script>
import * as apiCausas from "@/services/causas.js";
export default {
  props: {
    causa: Object,
  },
  methods: {
    async eliminarCausa(causa) {
      await apiCausas.eliminar(causa.id);
      this.$root.$emit("userMessage", this.$t("Causa eliminada"));
      this.$emit("causaEliminada", causa.id);
    },
  },
};
</script>
