<template>
  <page-listado title="Causas" con-busqueda>
    <filtro-activo field="activa" class="float-left mr-3 d-md-block d-none" />

    <template slot="toolbar">
      <b-button variant="primary" size="sm" to="/administracion/causas/crear">
        <v-icon name="plus" width="16"></v-icon>
        {{ $t("Crear") }}
      </b-button>
    </template>

    <tabla :causas="resultado.causas" @causaEliminada="causaEliminada"></tabla>

    <paginacion
      v-if="resultado.numeroPaginas > 1"
      :page-count="resultado.numeroPaginas"
      :page="resultado.pagina"
      :pageRange="3"
    ></paginacion>
  </page-listado>
</template>

<script>
import * as apiCausas from "@/services/causas.js";
import Tabla from "./Tabla.vue";
export default {
  components: { Tabla },
  data() {
    return {
      resultado: {
        causas: [],
        numeroPaginas: 0,
        pagina: 1,
      },
    };
  },
  methods: {
    async listar() {
      this.resultado = await apiCausas.listar(this.$route.query);
    },
    causaEliminada(id) {
      this.listar();
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
